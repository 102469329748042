<template>
  <block>
    <div
      v-if="hasFilterFields || computedActions"
      class="action-bar flex-between flex-wrap padding-m-vertical margin-xl-bottom relative z-level-2">
      <div v-if="displayItems.length > 0" class="filter-controls flex gap-m">
        <form-button
          v-if="hasFilterFields"
          icon="magnify"
          :theme="filtersActive ? 'alt' : 'gray-5'"
          text="core.crudV1.filterToggleText"
          @click.prevent="filtersActive = !filtersActive"></form-button>

        <slot
          name="general-buttons"
          v-bind="{
            isAnyItemSelected,
            selectedItems,
            areAllItemsSelected,
            items: displayItems,
          }">
        </slot>
      </div>

      <div class="top-actions flex flex-middle gap-l animate__fadeIn animate--fast">
        <slot
          name="general-actions"
          v-bind="{ isAnyItemSelected, selectedItems, areAllItemsSelected }">
          <transition-group
            name="custom-classes"
            enter-active-class="animate__zoomIn animate--fastest"
            leave-active-class="animate__zoomOut animate--fastest">
            <div
              v-for="(action, index) of computedMassActions.custom"
              v-if="isAnyItemSelected && computedMassActions"
              :key="`custom-${index}`"
              class="">
              <form-button
                v-tooltip="action.tooltip ? action.tooltip : false"
                :icon="action.icon ? action.icon : 'shape-square'"
                :theme="action.theme ? action.theme : 'lead'"
                :show-slot="!!action.text"
                :text="action.text ? action.text : false"
                @click="runCustomMassActionHandler(index, action)"></form-button>
            </div>

            <div
              v-if="
                isAnyItemSelected &&
                computedMassActionsEnabled &&
                computedMassActions.delete
              "
              :key="1"
              class="">
              <form-button
                theme="danger"
                icon="trash"
                @click="initDeleteSelectedItemsFlow"
                >{{ translate("core.crudV1.actions.deleteSelected") }}</form-button
              >
            </div>
            <div v-if="computedActions?.baseActions?.create" key="2" class="">
              <form-button
                theme="success"
                class="animate__fadeIn animate--fast"
                icon="plus"
                @click="goToCreate"
                >{{ translate("core.createNew") }}</form-button
              >
            </div>
          </transition-group>
        </slot>
      </div>

      <div
        v-show="filtersActive && hasFilterFields"
        class="filter width-expand flex flex-gap-l flex-wrap flex-start margin-l-top animate__fadeIn animate--fast margin-m-bottom"
        style="max-width: 100%">
        <form-input
          v-for="(field, fieldIndex) of displayFilterFields"
          :key="fieldIndex"
          v-bind="field"
          v-model="desiredFilters[field.name]"
          style="min-width: 200px"
          theme-style="minimal"></form-input>
      </div>
    </div>

    <h3
      v-if="displayItems.length < 1 && !isLoading"
      class="animate__fadeIn animate--slow">
      {{ translateSafe(noItemsFoundText) }}
    </h3>

    <div v-else class="item-table-wrapper relative z-level-1">
      <bar-loader
        v-if="
          willFilterSoon &&
          (displayItems.length > 0 || !asyncOps.asyncStatus.asyncDataLoading)
        "
        theme="alt"
        style="width: 100%"
        :active="
          willFilterSoon &&
          (displayItems.length > 0 || !asyncOps.asyncStatus.asyncDataLoading)
        "
        :fixed="false"></bar-loader>
      <div class="width-expand overflow-x-scroll">
        <table
          v-show="displayItems.length > 0"
          class="crud-list-table table table--highlight width-expand overflow-x-scroll"
          :class="{ 'is-refreshed': tableRefreshIndicator }">
          <!--table--no-strips  table--tight-->
          <thead>
            <tr>
              <th
                v-if="computedMassActionsEnabled"
                class="table--shrink table--center item-select-th">
                <form-input
                  type="checkbox"
                  :label="false"
                  :checked="areAllItemsSelected"
                  @click="toggleAllSelection"></form-input>
              </th>
              <th
                v-if="showIdField"
                class="table--shrink table--center orderable"
                :class="{ 'is-current-ordering': isColCurrentOrdering('id') }"
                @click="quickSetListOrdering('id')">
                <div class="flex flex-start flex-middle gap-s">
                  <span>{{ translate("core.crudV1.fields.id") }}</span>
                  <icon
                    v-if="isColCurrentOrderingAsc('id')"
                    size="1"
                    icon="chevron-up"></icon>
                  <icon
                    v-if="isColCurrentOrderingDesc('id')"
                    size="1"
                    icon="chevron-down"></icon>
                </div>
              </th>
              <th
                v-for="(field, index) of displayFields"
                key="index"
                class=" "
                :class="{
                  'is-current-ordering': isColCurrentOrdering(field.itemKey),
                  orderable: field.orderable,
                }"
                :field="field"
                @click="field.orderable ? quickSetListOrdering(field.itemKey) : ''">
                <div class="flex flex-start flex-middle gap-s">
                  <span> {{ translate(field.label) }}</span>
                  <icon
                    v-if="isColCurrentOrderingAsc(field.itemKey)"
                    size="1"
                    icon="chevron-up"></icon>
                  <icon
                    v-if="isColCurrentOrderingDesc(field.itemKey)"
                    size="1"
                    icon="chevron-down"></icon>
                </div>
              </th>
              <th v-if="computedActions">
                {{ translate("core.crudV1.fields.actions") }}
              </th>
            </tr>
          </thead>

          <tbody
            v-show="displayItems.length > 0 || !asyncOps.asyncStatus.asyncDataLoading">
            <tr v-for="(item, index) of displayItems">
              <!-- mass action column -->
              <td v-if="computedMassActionsEnabled" class="table--shrink table--center">
                <form-input
                  type="checkbox"
                  :label="false"
                  :checked="isItemSelected(item)"
                  @click="toggleItemSelection(item)"></form-input>
              </td>

              <!-- id column -->
              <td v-if="showIdField" class="table--shrink table--center">
                <span class="inline-block" style="min-width: 50px; text-align: start">{{
                  item.id || "-"
                }}</span>
              </td>

              <!-- item columns -->
              <td
                v-for="(field, fieldIndex) of displayFields"
                :key="index + '-' + fieldIndex"
                class=""
                :class="{ 'has-quick-edit': !!field.quickEdit }"
                @click="field.quickEdit ? startQuickEdit(item, field) : ''">
                <slot
                  :name="'cell-' + fieldIndex"
                  v-bind="{ field, item, index, fieldIndex }">
                  <inline-block style="min-width: 120px">{{
                    item[field.itemKey] || "-"
                  }}</inline-block>
                </slot>
              </td>

              <!-- actions -->
              <td v-if="computedActions" class="table--shrink" style="min-width: 150px">
                <slot
                  v-if="computedActions"
                  :name="'cell-actions'"
                  v-bind="{ item, index, crudList: this }">
                  <div class="flex gap-m">
                    <slot
                      :name="'cell-actions-extra-before'"
                      v-bind="{ item, index, crudList: this }"></slot>

                    <form-button
                      v-for="(action, actionIndex) of computedActions?.custom"
                      v-show="shouldShowCustomAction(action, item)"
                      :key="actionIndex"
                      v-tooltip="action.tooltip ? action.tooltip : false"
                      slim="true"
                      :icon="action.icon ? action.icon : 'shape-square'"
                      :theme="action.theme ? action.theme : 'lead'"
                      :show-slot="!!action.text"
                      :text="action.text ? action.text : false"
                      @click="runCustomActionHandler(index, item, action)"></form-button>
                    <form-button
                      v-if="computedActions?.baseActions.show"
                      slim="true"
                      icon="eye"
                      :show-slot="false"></form-button>
                    <form-button
                      v-if="computedActions?.baseActions.edit"
                      slim="true"
                      icon="pencil"
                      :show-slot="false"
                      @click="goToEdit(item)"></form-button>
                    <form-button
                      v-if="computedActions?.baseActions.delete"
                      slim="true"
                      icon="trash"
                      theme="danger"
                      :show-slot="false"
                      @click="initDeleteFlow(item)"></form-button>

                    <slot
                      :name="'cell-actions-extra-after'"
                      v-bind="{ item, index, crudList: this }"></slot>
                  </div>
                </slot>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <Skeleton
        class="margin-xl-top"
        style="min-height: 600px"
        :template="'lines'"
        :count="10"
        :fit-height="false"
        :default-height="500"
        :content-ready="!isLoading">
      </Skeleton>

      <pagination
        v-if="pagination.show"
        v-model:pageSize="pagination.limit"
        v-model:currentPage="pagination.start"
        class="margin-xl-top"
        :total-count="listData.totalCount ? listData.totalCount : 1"
        :handles-page-size="true"
        :page-size="pagination.limit"
        :force-show="true"></pagination>
    </div>
  </block>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import { computed } from "vue";

export default {
  components: {},
  props: {
    subject: {
      type: String,
      default: "generic",
    },
    listSrc: {
      type: String,
      default: false,
    },
    showIdField: {
      type: Boolean,
      default: true,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    initialOrdering: {
      type: Object,
      default: {
        key: "id",
        direction: "desc",
      },
    },
    enableMassActions: {
      type: Boolean,
      default: true,
    },
    customMassActions: {
      type: [Object, Boolean],
      default: false,
    },
    publishable: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Object,
      default: {},
    },
    actions: {
      type: Object,
      default: {},
    },
    massActions: {
      type: Object,
      default: {},
    },
    /*
     * false - auto, string - route name, object - router config, function - callback
     */
    createRoute: {
      type: [Boolean, String, Object, Function],
      default: false,
    },
    editRoute: {
      type: [Boolean, String, Object, Function],
      default: false,
    },
    createRoutePrefix: {
      type: [String, Boolean],
      default: "",
    },
    editRoutePrefix: {
      type: [String, Boolean],
      default: "",
    },
    deleteRoute: {
      type: [Boolean, String, Function],
      default: false,
    },
    deleteRoutePrefix: {
      type: [String, Boolean],
      default: "",
    },
    initialFilters: {
      type: [Object, Boolean],
      default: false,
    },
    constantFilters: {
      type: Object,
      default: {},
    },
    additionalGetListData: {
      type: Object,
      default: {},
    },
    noItemsFoundText: {
      type: String,
      default: "core.crud.noItemsFound",
    },
    paginationConfig: {
      type: Object,
      default: () => ({
        start: 0,
        limit: 25,
        show: true,
      }),
    },
  },
  emits: ["crud-list:items-updated"],
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    return { asyncOps, asyncOpsReady, asyncStatus };
  },
  data: function () {
    return {
      tableRefreshIndicator: false,
      refreshIndicatorTimeout: null,
      refreshIndicatorDuration: 1000,
      listData: {},
      items: [],
      pagination: this.paginationConfig,
      ordering: this.initialOrdering,
      filters:
        this.initialFilters && typeof this.initialFilters === "object"
          ? { ...this.initialFilters }
          : {},
      desiredFilters:
        this.initialFilters && typeof this.initialFilters === "object"
          ? { ...this.initialFilters }
          : {},
      filterTimeout: null,
      filterDelay: 350,
      willFilterSoon: false,
      isLoading: true,
      loadingTimeout: null,
      loadingUiDelay: 200,
      filtersActive: false,
      canDisplayNoItems: false,
      selectedItems: [],
      asyncData: {
        listData: {
          target: computed(() => this.computedListSrc),
          data: computed(() => {
            return {
              pagination: this.requestPagination,
              filters: this.requestFilters,
              ordering: this.requestOrdering,
              ...this.additionalGetListData,
            };
          }),
        },
      },
    };
  },
  computed: {
    requestPagination() {
      return this.pagination;
    },
    requestFilters() {
      let result = {};

      for (const [key, value] of Object.entries(this.filters)) {
        if (value === "") {
          continue;
        }

        result[key] = value;
      }
      for (const [key, value] of Object.entries(this.constantFilters)) {
        if (value === "") {
          continue;
        }

        result[key] = value;
      }
      return result;
    },
    requestOrdering() {
      return this.ordering;
    },
    displayItems() {
      return this.listData && this.listData.items ? this.listData.items : [];
    },
    filterFields() {
      if (!this.fields) {
        return {};
      }

      let result = {};
      for (const [key, field] of Object.entries(this.fields)) {
        if (!field.hasOwnProperty("filterable") || !field.filterable) {
          continue;
        }

        result[key] = {
          itemKey: field.itemKey,
          name: field.itemKey,
          type: "text",
          placeholder: field.label,
        };

        if (field.field) {
          // allow override from the field key
          result[key] = { ...result[key], ...field.field };
        }

        // allow override when filterable is object
        if (typeof field.filterable === "object") {
          result[key] = {
            ...result[key],
            ...field.filterable,
          };
        }
      }

      return result;
    },
    hasFilterFields() {
      let filters = this.displayFilterFields;
      return Object.entries(filters).length > 0;
    },
    displayFilterFields() {
      let fields = this.filterFields;
      let result = {};
      for (const [key, field] of Object.entries(fields)) {
        if (field.hardCoded) {
          continue;
        }

        if (field.hidden) {
          continue;
        }

        result[key] = field;
      }

      return result;
    },
    displayFields() {
      if (!this.fields) {
        return {};
      }

      let result = {};
      for (const [key, field] of Object.entries(this.fields)) {
        let safeField = {
          ...field,
        };

        if (field.listable === false) {
          continue;
        }
        // enforce integrity
        if (!safeField.hasOwnProperty("sortable")) {
          safeField.sortable = true;
        }

        if (!safeField.hasOwnProperty("label")) {
          safeField.label = "core.crudV1.fields.noLabel";
        }

        if (!safeField.hasOwnProperty("label")) {
          safeField.label = "core.crudV1.fields.noLabel";
        }

        if (!safeField.hasOwnProperty("orderable")) {
          safeField.orderable = true;
        }

        if (!safeField.hasOwnProperty("quickEdit")) {
          safeField.quickEdit = false;
        }

        result[key] = safeField;
      }
      return result;
    },
    computedListSrc() {
      if (this.listSrc) {
        return this.listSrc;
      }

      return this.listSrcPrefix + this.subject;
    },
    areAllItemsSelected() {
      return (
        this.selectedItems.length > 0 &&
        this.selectedItems.length === this.displayItems.length
      );
    },
    areItemsSelected() {
      return this.isAnyItemSelected;
    },
    isAnyItemSelected() {
      return this.selectedItems.length > 0;
    },
    selectedIds() {
      return this.selectedItems.map((item) => item.id);
    },
    computedActions() {
      if (this.actions === false) {
        return false;
      }
      let result = {
        baseActions: {},
        custom: {},
      };

      if (this.actions.show !== false) {
        result.baseActions.show = true;
      }

      if (this.actions.edit !== false) {
        result.baseActions.edit = true;
      }

      if (this.actions.delete !== false) {
        result.baseActions.delete = true;
      }

      if (this.actions.create !== false) {
        result.baseActions.create = true;
      }

      if (!this.actions.custom || typeof this.actions.custom !== "object") {
        return result;
      }

      for (const [name, conf] of Object.entries(this.actions.custom)) {
        result.custom[name] = conf;
      }

      return result;
    },
    computedMassActionsEnabled() {
      if (!this.enableMassActions) {
        return false;
      }

      if (!this.massActions) {
        return false;
      }

      return true;
    },
    computedMassActions() {
      let result = {
        delete: true,
        custom: {},
      };

      if (!this.computedMassActionsEnabled) {
        return {};
      }

      // base actions
      if (
        this.massActions.hasOwnProperty("delete") &&
        !this.massActions.hasOwnProperty("delete")
      ) {
        result.delete = false;
      }
      // handle custom actions, if available
      if (!this.massActions.custom || typeof this.massActions.custom !== "object") {
        return result;
      }

      for (const [name, conf] of Object.entries(this.massActions.custom)) {
        result.custom[name] = conf;
      }

      return result;
    },
  },
  watch: {
    listData: {
      deep: true,
      handler(newVal, oldVal) {
        this.deselectAllItems();
        this.$emit("crud-list:items-updated", newVal.items);
        if (!newVal || !newVal.items) {
          return;
        }

        this.hideTableRefreshIndicator();
        this.$nextTick(() => {
          this.showTableRefreshIndicator();
        });
      },
    },
    "asyncOps.asyncStatus.asyncDataLoading": {
      immediate: true,
      handler(val) {
        if (val) {
          this.loadingTimeout = utilities.setClientTimeout(() => {
            this.isLoading = true;
          }, this.loadingUiDelay);
          this.canDisplayNoItems = true;
        } else {
          clearTimeout(this.loadingTimeout);
          this.isLoading = false;
        }
      },
    },
    desiredFilters: {
      deep: true,
      handler(newVal) {
        clearTimeout(this.filterTimeout);
        this.willFilterSoon = true;
        this.filterTimeout = utilities.setClientTimeout(() => {
          let newFilters = {};
          for (const [key, value] of Object.entries(newVal)) {
            if (value === null || value === undefined) {
              continue;
            }
            newFilters[key] = value;
          }
          this.filters = newFilters;
          this.willFilterSoon = false;
        }, this.filterDelay);
      },
    },
    filters: {
      deep: true,
      handler(newVal) {},
    },
  },
  methods: {
    reverseOrderingDirection() {
      if (this.ordering.direction === "asc") {
        this.ordering.direction = "desc";
      } else {
        this.ordering.direction = "asc";
      }

      return this;
    },
    quickSetListOrdering(key) {
      if (this.isColCurrentOrdering(key)) {
        return this.reverseOrderingDirection();
      }

      this.ordering = {
        key: key,
        direction: "asc",
      };
    },
    isColCurrentOrdering(col) {
      return this.ordering && this.ordering.key && this.ordering.key === col;
    },
    isColCurrentOrderingAsc(col) {
      return (
        this.isColCurrentOrdering(col) &&
        this.ordering &&
        this.ordering.direction &&
        this.ordering.direction === "asc"
      );
    },
    isColCurrentOrderingDesc(col) {
      return (
        this.isColCurrentOrdering(col) &&
        this.ordering &&
        this.ordering.direction &&
        this.ordering.direction === "desc"
      );
    },
    goToCreate() {
      if (typeof this.createRoute === "function") {
        return this.createRoute();
      }

      if (!this.createRoute) {
        return this.$router.push({
          name: this.createRoutePrefix + this.subject + "-create",
        });
      }

      if (typeof this.createRoute === "object") {
        this.$router.push(this.createRoute);
      }

      if (typeof this.createRoute === "string") {
        this.$router.push({ name: this.createRoute });
      }
    },
    goToEdit(item) {
      if (typeof this.editRoute === "function") {
        return this.editRoute(item);
      }

      if (!this.editRoute) {
        return this.$router.push({
          name: this.editRoutePrefix + this.subject + "-edit-id",
          params: { id: item.id },
        });
      }

      if (typeof this.editRoute === "object") {
        let pushTarget = { ...this.editRoute };
        if (!pushTarget.params) {
          pushTarget.params = {};
        }

        if (!pushTarget.params.id) {
          pushTarget.params.id = item.id;
        }
        this.$router.push(pushTarget);
      }

      if (typeof this.editRoute === "string") {
        this.$router.push({
          name: this.editRoute,
          params: { id: item.id },
        });
      }
    },
    getDeleteRoute(items) {
      if (typeof this.deleteRoute === "function") {
        return this.deleteRoute(items);
      }

      if (typeof this.deleteRoute === "string" && this.deleteRoute !== "") {
        return this.deleteRoute;
      }

      if (typeof this.createRoute === "string" && this.createRoute !== "") {
        return this.createRoute;
      }

      return this.deleteRoutePrefix + this.subject;
    },
    async deleteItem(item) {
      return this.deleteItems([item]);
    },
    async deleteSelectedItems() {
      return this.deleteItems(this.selectedItems);
    },
    async deleteItems(items) {
      let ids = items.map((item) => {
        if (typeof item === "number") {
          return item;
        }
        if (typeof item !== "object" || !item || !item.hasOwnProperty("id")) {
          debug(
            "invalid argument for delete itesm. each item must either be an ID of an item, or an object with an id property",
            { items, item }
          );
          return 0;
        }
        return item.id;
      });

      let result = await this.asyncOps.asyncCall(
        this.getDeleteRoute(),
        { ids },
        { method: "delete" }
      );

      if (result.isError) {
        return this.$s.ui.notification(
          "core.crudV1.deleteActionErrorNotification",
          "error"
        );
      }

      this.$s.ui.notification("core.crudV1.deleteActionSuccessNotification", "success");
      this.refreshAsyncData();
    },
    initDeleteFlow(item) {
      this.$s.ui.modal.confirm("core.crudV1.deleteModalConfirmText").then(
        () => {
          this.deleteItems([item]);
        },
        () => {
          this.$s.ui.notification("core.crudV1.deleteActionCanceledNotification");
        }
      );
    },
    initDeleteSelectedItemsFlow() {
      this.$s.ui.modal.confirm("core.crudV1.deleteModalConfirmText").then(
        () => {
          this.deleteSelectedItems();
        },
        () => {
          this.$s.ui.notification("core.crudV1.deleteActionCanceledNotification");
        }
      );
    },
    selectItem(item) {
      if (!this.selectedItems.includes(item)) {
        this.selectedItems.push(item);
      }

      return this;
    },
    deselectItem(item) {
      let index = this.selectedItems.indexOf(item);
      if (index < 0) {
        return;
      }
      this.selectedItems.splice(index, 1); // 2nd parameter means remove one item only
    },
    toggleItemSelection(item) {
      if (this.isItemSelected(item)) {
        this.deselectItem(item);
      } else {
        this.selectItem(item);
      }
    },
    selectAllItems() {
      let itemsCopy = [].concat(this.displayItems);
      for (const item of itemsCopy) {
        if (!this.isItemSelected(item)) {
          this.selectItem(item);
        }
      }
    },
    deselectAllItems() {
      this.selectedItems.length = 0;
    },
    isItemSelected(target) {
      let targetId = typeof target === "object" ? target.id : target;
      let result = this.selectedItems.find((item) => {
        return item.id == targetId;
      });

      return typeof result !== "undefined";
    },
    toggleAllSelection() {
      if (this.areAllItemsSelected) {
        this.deselectAllItems();
      } else {
        this.selectAllItems();
      }
    },
    async runCustomActionHandler(key, item, actionConfig) {
      if (actionConfig.handler && typeof actionConfig.handler === "function") {
        actionConfig.handler({ crudList: this, item: item, actionConfig });
      }
    },
    async runCustomMassActionHandler(key, actionConfig) {
      if (actionConfig.handler && typeof actionConfig.handler === "function") {
        actionConfig.handler({
          crudList: this,
          items: this.selectedItems,
          actionConfig,
        });
      }
    },
    async reloadItems() {
      return this.refreshAsyncData();
    },
    hideTableRefreshIndicator() {
      clearTimeout(this.refreshIndicatorTimeout);
      this.tableRefreshIndicator = false;
    },
    showTableRefreshIndicator() {
      this.hideTableRefreshIndicator();
      this.$nextTick(() => {
        this.tableRefreshIndicator = true;

        if (utilities.isClient()) {
          this.refreshIndicatorTimeout = setTimeout(() => {
            this.hideTableRefreshIndicator();
          }, this.refreshIndicatorDuration);
        } else {
          this.hideTableRefreshIndicator();
        }
      });
    },
    setFilters(filters) {
      this.desiredFilters = { ...this.desiredFilters, ...filters };
    },
    async startQuickEdit(item, field) {
      let conf = field.quickEdit;
      if (!conf || typeof conf !== "object") {
        conf = {};
      }
      let type = conf.type ? conf.type : "prompt";
      let url = conf.url ? conf.url : `${this.computedListSrc}/${item.id}`;
      let targetKey = conf.targetKey ? conf.targetKey : field.itemKey;
      let label = conf.label ? conf.label : field.label;
      let promptResult;
      let editResult;
      let newValue;
      try {
        switch (type) {
          case "binary":
            newValue = !item[targetKey];
            break;
          case "binaryInteger":
            newValue = !item[targetKey];
            if (newValue) {
              newValue = 1;
            } else {
              newValue = 0;
            }
            break;
          case "prompt":
            const editField =
              conf.field && typeof conf.field === "object" ? conf.field : {};
            promptResult = await this.$s.ui.modal.prompt(
              "core.crud.quickEditPromptExplain",
              "core.crud.quickEditPromptTitle",
              {
                field: { label: label, name: targetKey, ...editField },
                initialValue: item[field.itemKey],
              }
            );
            newValue =
              promptResult && typeof promptResult === "object"
                ? promptResult.data[targetKey]
                : promptResult;
            break;
        }

        editResult = await this.asyncOps.asyncCall(
          url,
          { [targetKey]: newValue },
          { method: "patch" }
        );
      } catch (e) {
        this.$s.ui.notification("core.crud.quickEditAborted");
        return;
      }

      // parse the response, to extract value to update. this is future proofed (for multiple fields/complext data)
      if (editResult.isError) {
        this.$s.ui.notification("core.crud.quickEditError", "error");
        return;
      }
      // success
      console.log("setting value 1");
      console.log(newValue);
      item[field.itemKey] = newValue;

      this.$s.ui.notification("core.crud.quickEditSuccess", "success");
    },
    shouldShowCustomAction(action, item) {
      if (typeof action.show === "undefined") {
        return true;
      }

      if (action.show === false) {
        return false;
      }

      if (typeof action.show === "function") {
        return action.show(item);
      }

      return true;
    },
  },
  serverPrefetch() {
    return this.getSSRPrefetchPromise();
  },
};
</script>

<style scoped lang="scss">
.crud-list-table {
  &.is-refreshed {
    position: relative;

    &::after {
      border-radius: var(--border-radius-s);
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      pointer-events: none;
      z-index: 5;
      background-color: rgba(0, 0, 0, 0);
      animation: pulse-list-table 1s infinite;
    }
  }

  th.orderable {
    cursor: pointer;
    transition: opacity 200ms ease, color 200ms ease;
    color: var(--c-inverse);
    &:hover {
      opacity: 0.8;
      color: var(--c-alt);
    }
  }

  th.is-current-ordering {
    color: var(--c-alt);
  }

  th:not(.orderable) {
    cursor: not-allowed;
    color: var(--c-inverse);
  }

  th.item-select-th {
    cursor: auto;
  }

  @-webkit-keyframes pulse-list-table {
    0% {
      background-color: rgba(0, 0, 0, 0);
    }
    30% {
      background-color: rgba(0, 0, 0, 0.1);
    }
    100% {
      background-color: rgba(0, 0, 0, 0);
    }
  }
  @keyframes pulse-list-table {
    0% {
      background-color: rgba(0, 0, 0, 0);
    }
    30% {
      background-color: rgba(0, 0, 0, 0.1);
    }
    100% {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .has-quick-edit {
    cursor: pointer;
  }
}
</style>
